import React, { useState, useEffect } from "react";
import { useNotifications } from "@mantine/notifications";
import CustomMultiSelect from "./multiSelect";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/16/solid";

const ACTIONS = [
  {
    actionName: "SetObjectProperty",
    input: ["id", "property", "value"],
    type: "dtdl",
  },
  { actionName: "StartMonitor", input: ["monitorId"], type: "monitor" },
  { actionName: "StopMonitor", input: ["monitorId"], type: "monitor" },
  { actionName: "StartSequence", input: ["sequenceId"], type: "sequence" },
  { actionName: "StopSequence", input: ["sequenceId"], type: "sequence" },
  // {
  //   actionName: "EvaluateInterlock",
  //   input: ["interlockId"],
  //   type: "interlock",
  // },
];
// EventPanel Component
const EventPanel = ({ selectedObject, allSchemas, allObject, onUpdate }) => {
  const notifications = useNotifications();
  const [selectedEvent, setSelectedEvent] = useState("");
  const [commands, setCommands] = useState([]);
  const [currentObjectSchema, setCurrentObjectSchema] = useState(null);
  const [requestPayload, setRequestPayload] = useState({});
  const [isShowAddEvent, setShowAddEvent] = useState(false);
  const [currentEvents, setCurrentEvents] = useState(null);
  const [currentConditions, setCurrentConditions] = useState(null);
  const [selectedAction, setSelectedAction] = useState("");
  const [dtdlList, setDtdlList] = useState([]);
  const [dtdlSchemaList, setDtdlSchemaList] = useState([]);
  const [dtdlPropertyList, setDtdlPropertyList] = useState([]);
  const [selectedDeviceName, setSelectedDeviceName] = useState("");
  const [selectedDeviceProperty, setSelectedDeviceProperty] = useState("");
  const [selectedStartMonitor, setSelectedStartMonitor] = useState([]);
  const [selectedStopMonitor, setSelectedStopMonitor] = useState([]);
  const [monitors, setMonitors] = useState([]);
  const [selectedStartSequence, setSelectedStartSequence] = useState([]);
  const [selectedStopSequence, setSelectedStopSequence] = useState([]);
  const [sequences, setSequences] = useState([]);
  const [selectedInterlock, setSelectedInterlock] = useState([]);
  const [interlocks, setInterlocks] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (selectedObject) {
      const schema = allSchemas.find(
        (schema) => schema["@id"] === selectedObject["$metadata"]["$model"]
      );
      setCurrentObjectSchema(schema);
      setCommands(
        schema?.contents.filter((content) => content["@type"] === "Command") ||
          []
      );
      setSelectedEvent("");
      initObjectEvent();
      resetSelections();
      setShowAddEvent(false);
      getInterlocks();
      getMonitors();
      getSequences();
    }
  }, [selectedObject, allSchemas]);

  useEffect(() => {
    if (allObject && allSchemas) {
      const filteredSchema = getFilteredSchema(allSchemas);
      const filteredDtdlList = getFilteredDtdlList(allObject, filteredSchema);
      setDtdlList(filteredDtdlList);
      setDtdlSchemaList(filteredSchema);
    }
  }, [allObject, allSchemas]);

  const initObjectEvent = async () => {
    setLoading(true);
    const resp = await fetchObjectByID(selectedObject["$dtId"]);
    setCurrentEvents(resp?.events);
    setLoading(false);
  };

  const resetSelections = () => {
    setSelectedAction("");
    setSelectedDeviceName("");
    setSelectedDeviceProperty("");
    setSelectedStartMonitor([]);
    setSelectedStopMonitor([]);
    setSelectedInterlock([]);
    setSelectedStartSequence([]);
    setSelectedStopSequence([]);
  };

  const getFilteredSchema = (schemas) => {
    return schemas
      .map((item) => ({
        ...item,
        contents:
          item.contents?.filter(
            (content) =>
              (content.displayName || content.name) &&
              content["@type"] === "Telemetry"
          ) || [],
      }))
      .filter((item) => item.contents.length > 0);
  };

  const getFilteredDtdlList = (objects, schema) => {
    return objects
      .filter((device) =>
        schema.some((iface) => device.$metadata.$model === iface["@id"])
      )
      .map((device) => ({
        ...device,
        value: device["$dtId"],
        label: device.Name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  const updatePropertyList = (type) => {
    setSelectedDeviceName(type);
    const selectedDevice = dtdlList?.find((x) => x.value == type);
    const selectedDeviceModel = selectedDevice?.$metadata?.$model;
    const filteredCommandList = dtdlSchemaList
      ?.find((e) => e["@id"] == selectedDeviceModel)
      ?.contents?.map((info) => ({
        ...info,
        value: info.name,
        label: info.displayName || info.name,
      }));
    setDtdlPropertyList(filteredCommandList || []);
  };

  const fetchData = async (url, setter) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      setter(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSelectionChange = (setter) => (e) => {
    if (e.target.value) {
      setter(e.target.value);
    }
  };

  const handleEventChange = (e) => {
    setSelectedEvent(e.target.value);
    const selConditions = currentEvents?.find(
      (s) => s.eventName === e.target.value
    );
    setCurrentConditions(selConditions?.conditions);
    resetSelections();
  };

  const handleActionChange = async (e) => {
    setSelectedAction(e.target.value);
    const selectedActionType = ACTIONS.find(
      (action) => action.actionName === e.target.value
    )?.type;
    // if (selectedActionType === "monitor") await getMonitors();
    // else if (selectedActionType === "sequence") await getSequences();
    // else if (selectedActionType === "interlock") await getInterlocks();
    updateActionInputs(e.target.value);
  };

  const getInterlocks = async () => {
    return await fetchData(
      `${process.env.REACT_APP_VIA_RULE_ENGINE_API}/api/Rule/ByDevice/${selectedObject["$dtId"]}`,
      setInterlocks
    );
  };
  const getMonitors = async () => {
    return await fetchData(
      `${process.env.REACT_APP_VIA_MONITORWORKFLOW_API}/api/Monitor/1/100`,
      setMonitors
    );
  };
  const getSequences = async () => {
    return await fetchData(
      `${process.env.REACT_APP_VIA_RULE_ENGINE_API}/api/Sequence`,
      setSequences
    );
  };

  const updateActionInputs = (actionName, conditions = currentConditions) => {
    const currentEventAction = conditions?.[0]?.eventActions?.find(
      (val) => val.actionName == actionName
    );
    if (!currentEventAction) return;

    const { input } = currentEventAction;
    switch (actionName) {
      case "SetObjectProperty":
        setSelectedDeviceName(input?.id);
        updatePropertyList(input?.id);
        setSelectedDeviceProperty(input.property);
        setRequestPayload({ [input.property]: input.value });
        break;
      case "EvaluateInterlock":
        setSelectedInterlock(input);
        break;
      case "StartMonitor":
        setSelectedStartMonitor(input);
        break;
      case "StopMonitor":
        setSelectedStopMonitor(input);
        break;
      case "StartSequence":
        setSelectedStartSequence(input);
        break;
      case "StopSequence":
        setSelectedStopSequence(input);
        break;
      default:
        break;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const field = dtdlPropertyList.find(
      (e) => e.value == selectedDeviceProperty
    );
    let convertedValue = convertValue(value, field?.schema);
    setRequestPayload((prev) => ({ [name]: convertedValue }));
  };

  const convertValue = (value, schema) => {
    switch (schema) {
      case "double":
      case "float":
      case "long":
        return parseFloat(value) || 0;
      case "integer":
        return parseInt(value, 10);
      case "boolean":
        return value === "true";
      default:
        return value;
    }
  };

  const saveObjectEvent = async (e) => {
    e.preventDefault();
    const apiUrl = `${process.env.REACT_APP_VIA_OBJECT_API}/api/ObjectEvent`;
    const input = getActionInput();
    if (!selectedEvent || !selectedAction) {
      notifications.showNotification({
        title: "missing fields",
        message: "",
        autoClose: 5000,
        color: "red",
        loading: false,
        disallowClose: false,
      });
      return;
    }
    const eventActions = getEventActions(input);
    const events = getEvents(eventActions);
    const payload = {
      objectId: selectedObject["$dtId"],
      events: events,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: { Accept: "*/*", "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      notifications.showNotification({
        title: "event saved successfully",
        message: "",
        autoClose: 5000,
        color: "green",
        loading: false,
        disallowClose: false,
      });
      console.log("Command save successful:", data);
      setSelectedEvent("");
      resetSelections();
      setCurrentEvents(data?.events);
      onUpdate(true);
      setShowAddEvent(false);
    } catch (error) {
      console.error("Error save command:", error);
    }
  };
  const getEventActions = (input) => {
    const currentEventActions = currentConditions?.[0]?.eventActions || [];
    const eventActions = currentEventActions.reduce((acc, e) => {
      if (e.actionName === selectedAction) {
        if (!input) {
          return acc;
        }
        if (Array.isArray(input) && input.length === 0) {
          // Skip this action if input is an empty array
          return acc;
        }

        return [...acc, { actionName: selectedAction, input }];
      }
      return [...acc, e];
    }, []);

    if (
      !eventActions.some((e) => e.actionName === selectedAction) &&
      input &&
      !(Array.isArray(input) && input.length === 0)
    ) {
      eventActions.push({ actionName: selectedAction, input });
    }

    return eventActions;
  };
  const getEvents = (eventActions) => {
    const selEvents = currentEvents || [];

    const event = selEvents.reduce((acc, e) => {
      if (e?.eventName === selectedEvent) {
        if (Array.isArray(eventActions) && eventActions.length === 0) {
          return acc;
        }
        return [
          ...acc,
          {
            eventName: selectedEvent,
            conditions: [
              {
                expression: e?.conditions?.[0]?.expression || "",
                eventActions: eventActions,
              },
            ],
          },
        ];
      } else {
        return [...acc, e];
      }
    }, []); // Add an empty array as the initial value

    if (
      !selEvents.some((e) => e.eventName === selectedEvent) &&
      !(Array.isArray(eventActions) && eventActions.length === 0)
    ) {
      event.push({
        eventName: selectedEvent,
        conditions: [
          {
            expression: "",
            eventActions: eventActions,
          },
        ],
      });
    }
    return event;
  };

  const getActionInput = () => {
    switch (selectedAction) {
      case "SetObjectProperty":
        if (
          selectedDeviceName &&
          selectedDeviceProperty &&
          requestPayload[selectedDeviceProperty] != undefined
        ) {
          return {
            id: selectedDeviceName,
            property: selectedDeviceProperty,
            value: requestPayload[selectedDeviceProperty],
          };
        } else {
          return null;
        }
      case "EvaluateInterlock":
        if (selectedInterlock) {
          return [...selectedInterlock];
        } else {
          return null;
        }
      case "StartMonitor":
        if (selectedStartMonitor) {
          return [...selectedStartMonitor];
        } else {
          return null;
        }
      case "StopMonitor":
        if (selectedStopMonitor) {
          return [...selectedStopMonitor];
        } else {
          return null;
        }
      case "StartSequence":
        if (selectedStartSequence) {
          return [...selectedStartSequence];
        } else {
          return null;
        }
      case "StopSequence":
        if (selectedStopSequence) {
          return [...selectedStopSequence];
        } else {
          return null;
        }
      default:
        return null;
    }
  };

  const renderInputField = () => {
    const field = dtdlPropertyList.find(
      (e) => e.value == selectedDeviceProperty
    );
    if (!field) return null;

    const fieldCustomSchema = currentObjectSchema.schemas?.filter(
      (s) => s["@id"] === field.schema
    );

    switch (field.schema) {
      case "dateTime":
        return (
          <input
            type="datetime-local"
            name={field.name}
            onChange={handleInputChange}
            value={requestPayload[field.name]}
            className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
          />
        );
      case "double":
      case "integer":
        return (
          <input
            type="number"
            name={field.name}
            onChange={handleInputChange}
            value={requestPayload[field.name]}
            className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
          />
        );
      case "string":
        return (
          <input
            type="text"
            name={field.name}
            onChange={handleInputChange}
            value={requestPayload[field.name]}
            className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
          />
        );
      case "boolean":
        return (
          <select
            name={field.name}
            onChange={handleInputChange}
            className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
            value={requestPayload[field.name]}
          >
            <option value="">Select a value</option>
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        );
      default:
        if (
          field.schema["@type"] === "Enum" &&
          field.schema.hasOwnProperty("enumValues")
        ) {
          return (
            <select
              name={field.name}
              onChange={handleInputChange}
              value={requestPayload[field.name]}
              className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
            >
              {field.schema.enumValues.map((enumValue) => (
                <option key={enumValue.enumValue} value={enumValue.enumValue}>
                  {enumValue.name}
                </option>
              ))}
            </select>
          );
        } else if (
          fieldCustomSchema &&
          fieldCustomSchema.length > 0 &&
          fieldCustomSchema[0]["@type"] === "Enum"
        ) {
          return (
            <select
              name={field.name}
              onChange={handleInputChange}
              value={requestPayload[field.name]}
              className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
            >
              <option value="">Select a value</option>
              {fieldCustomSchema[0].enumValues.map((enumValue) => (
                <option key={enumValue.enumValue} value={enumValue.enumValue}>
                  {enumValue.name}
                </option>
              ))}
            </select>
          );
        }
        return null;
    }
  };

  const renderValueForm = (action) => {
    if (!action) return null;

    switch (action.type) {
      case "monitor":
      case "sequence":
      case "interlock":
        const options =
          action.type === "monitor"
            ? monitors?.filter((e) => e?.workflowType == 0)
            : action.type === "sequence"
            ? sequences
            : interlocks;
        const setter = getSetter(action);
        const value = getValue(action);
        const label = `${
          action.type.charAt(0).toUpperCase() + action.type.slice(1)
        }`;
        return (
          <div className="grid grid-cols-2 gap-2 items-start">
            <div className="space-y-1 w-full flex flex-col flex-1 items-start gap-1">
              <label
                htmlFor="event"
                className="block text-sm font-medium text-gray-700"
              >
                {`Select ${label}`}
              </label>
              <CustomMultiSelect
                options={options.map((option) => ({
                  value: option._id || option.sequenceName,
                  label:
                    option.payload?.sequenceName ||
                    option.sequenceName ||
                    option.ruleName,
                }))}
                value={value}
                onChange={(value) => setter(value)}
                placeholder={`Select  ${label}`}
                enableSearch={true}
              />
              {/* <select
                className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
                onChange={handleSelectionChange(setter)}
                value={value}
              >
                <option value="">{`Select a ${label}`}</option>
                {options.map((option, index) => (
                  <option key={index} value={option._id || option.id}>
                    {option.payload?.sequenceName ||
                      option.sequenceName ||
                      option.ruleName}
                  </option>
                ))}
              </select> */}
            </div>
          </div>
        );
      case "dtdl":
        return (
          <div className="grid grid-cols-3 gap-2 items-start">
            <div className="space-y-1 flex flex-col flex-1 items-start gap-1">
              <label
                htmlFor="event"
                className="block text-sm font-medium text-gray-700"
              >
                Select Device
              </label>
              <select
                className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
                onChange={(e) => updatePropertyList(e.target.value)}
                value={selectedDeviceName}
              >
                <option value="">Select a Device</option>
                {dtdlList.map((dtdl, index) => (
                  <option key={index} value={dtdl.value}>
                    {dtdl.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="space-y-1 flex flex-col flex-1 items-start gap-1">
              <label
                htmlFor="event"
                className="block text-sm font-medium text-gray-700"
              >
                Select Property
              </label>
              <select
                className="w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
                onChange={handleSelectionChange(setSelectedDeviceProperty)}
                value={selectedDeviceProperty}
              >
                <option value="">Select a Property</option>
                {dtdlPropertyList.map((prop, index) => (
                  <option key={index} value={prop.value}>
                    {prop.label}
                  </option>
                ))}
              </select>
            </div>
            {selectedDeviceProperty && (
              <div className="space-y-1 flex flex-col flex-1 items-start gap-1">
                <label
                  htmlFor="event"
                  className="block text-sm font-medium text-gray-700"
                >
                  Select Value
                </label>
                {renderInputField()}
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  const getSetter = (action) => {
    let setter = null;
    switch (action.actionName) {
      case "EvaluateInterlock":
        setter = setSelectedInterlock;
        break;
      case "StartMonitor":
        setter = setSelectedStartMonitor;
        break;
      case "StopMonitor":
        setter = setSelectedStopMonitor;
        break;
      case "StartSequence":
        setter = setSelectedStartSequence;
        break;
      case "StopSequence":
        setter = setSelectedStopSequence;
        break;
      default:
        break;
    }
    return setter;
  };

  const getValue = (action) => {
    let setter = null;
    switch (action.actionName) {
      case "EvaluateInterlock":
        setter = selectedInterlock;
        break;
      case "StartMonitor":
        setter = selectedStartMonitor;
        break;
      case "StopMonitor":
        setter = selectedStopMonitor;
        break;
      case "StartSequence":
        setter = selectedStartSequence;
        break;
      case "StopSequence":
        setter = selectedStopSequence;
        break;
      default:
        break;
    }
    return setter;
  };

  const fetchObjectByID = async (dtdlId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_VIA_OBJECT_API}/api/ObjectEvent/${dtdlId}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      return null;
    }
  };

  const getInputLabel = (value) => {
    let label = "";
    switch (value) {
      case "EvaluateInterlock":
        label = "Selected Interlock";
        break;
      case "StartMonitor":
        label = "Selected Monitors";
        break;
      case "StopMonitor":
        label = "Selected Monitors";
        break;
      case "StartSequence":
        label = "Selected Sequence";
        break;
      case "StopSequence":
        label = "Selected Sequence";
        break;
      default:
        break;
    }
    return label;
  };

  const getInputValue = (actionName, val) => {
    let value = "";
    switch (actionName) {
      case "EvaluateInterlock":
        const selInterLock = interlocks?.find((e) => e.id == val);
        value = selInterLock?.name;
        break;
      case "StartMonitor":
        const selStartMonitor = monitors?.find((e) => e._id == val);
        value = selStartMonitor?.payload?.sequenceName;
        break;
      case "StopMonitor":
        const selStopMonitor = monitors?.find((e) => e._id == val);
        value = selStopMonitor?.payload?.sequenceName;
        break;
      case "StartSequence":
        const selStartSequence = sequences?.find((e) => e.sequenceName == val);
        value = selStartSequence?.sequenceName;
        break;
      case "StopSequence":
        const selStopSequence = sequences?.find((e) => e.sequenceName == val);
        value = selStopSequence?.sequenceName;
        break;
      case "SetObjectProperty":
        const selectedDevice = dtdlList?.find((e) => e.value == val);
        value = selectedDevice?.label;
        break;
      default:
        break;
    }
    return value;
  };

  const handleEventActionEdit = (event, action) => {
    setSelectedEvent(event?.eventName);
    setCurrentConditions(event?.conditions);
    setSelectedAction(action?.actionName);
    updateActionInputs(action?.actionName, event?.conditions);
    setShowAddEvent(true);
  };

  const handleEventDelete = async (ev) => {
    const apiUrl = `${process.env.REACT_APP_VIA_OBJECT_API}/api/ObjectEvent/${selectedObject["$dtId"]}/${ev?.eventName}`;
    try {
      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: { Accept: "*/*", "Content-Type": "application/json" },
      });
      initObjectEvent();

      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      notifications.showNotification({
        title: "event deleted successfully",
        message: "",
        autoClose: 5000,
        color: "green",
        loading: false,
        disallowClose: false,
      });
      console.log("Command delete successful:", data);
      initObjectEvent();
      onUpdate(true);
    } catch (error) {
      console.error("Error save command:", error);
    }
  };

  if (loading) {
    return <div className="text-center py-4">Loading ...</div>;
  }

  return (
    <>
      {!isShowAddEvent && (
        <div className="flex flex-col space-y-2 px-1">
          <div className="flex items-center justify-between">
            <h1 className="font-semibold text-lg">Event Actions</h1>
            {currentEvents && (
              <button
                className="flex self-start h-10  px-4 py-2 bg-[#BBD6ED] rounded shadow hover:shadow-inner"
                onClick={() => {
                  setShowAddEvent(true);
                  setSelectedEvent("");
                }}
              >
                Add Event
              </button>
            )}
          </div>
          <div className="flex flex-col bg-white space-y-2 p-2 overflow-auto h-[calc(100vh-354px)]">
            {currentEvents &&
              currentEvents?.map((event) => (
                <div className="border rounded px-2 py-1 space-y-1">
                  <div className="flex justify-between items-center pt-1">
                    <h1 className="font-semibold text-base">
                      on:{event.eventName}
                    </h1>
                    <div className="flex gap-2">
                      <button
                        className="flex items-center justify-center border rounded p-1 hover:bg-slate-100"
                        onClick={() => handleEventDelete(event)}
                      >
                        {<TrashIcon className="w-4 h-4 text-blue-300" />}
                      </button>
                    </div>
                  </div>
                  <div className="flex  flex-col gap-1">
                    {event.conditions?.map((condition) => (
                      <>
                        {condition?.eventActions?.map((action) => (
                          <div className="flex flex-col bg-slate-100 border rounded px-2 space-y-1">
                            <div className="flex justify-between items-center pt-1">
                              <div className="font-semibold">
                                {action.actionName}
                              </div>
                              <div className="flex gap-2">
                                <button
                                  className="flex items-center justify-center border rounded p-1 hover:bg-white"
                                  onClick={() =>
                                    handleEventActionEdit(event, action)
                                  }
                                >
                                  {
                                    <PencilSquareIcon className="w-4 h-4 text-blue-300" />
                                  }
                                </button>
                              </div>
                            </div>

                            {Array.isArray(action?.input) && (
                              <div className="flex flex-col gap-1">
                                <p className="text-sm font-semibold">
                                  {getInputLabel(action.actionName)}
                                </p>
                                <div className="flex flex-wrap gap-2">
                                  {action?.input?.map((v, index) => (
                                    <div
                                      key={index}
                                      className="flex flex-wrap "
                                    >
                                      {getInputValue(action.actionName, v)}
                                      {index < action.input.length - 1 && ", "}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                            {!Array.isArray(action?.input) && (
                              <div className="flex flex-wrap gap-2">
                                <div className="flex flex-col gap-1">
                                  <p className="text-sm font-semibold">
                                    Selected Device
                                  </p>
                                  <p>
                                    {getInputValue(
                                      action.actionName,
                                      action?.input?.id
                                    )}
                                  </p>
                                </div>
                                <div className="flex flex-col gap-1">
                                  <p className="text-sm font-semibold">
                                    Selected Property
                                  </p>
                                  <p>{action?.input?.property}</p>
                                </div>
                                <div className="flex flex-col gap-1">
                                  <p className="text-sm font-semibold">
                                    Selected value
                                  </p>
                                  <p>{action?.input?.value?.toString()}</p>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </>
                    ))}
                  </div>
                </div>
              ))}
            {!currentEvents && (
              <div className="flex flex-col items-center justify-center">
                <h2 className="text-xl font-bold text-gray-800 mb-2 mt-6">
                  Ooops! It's Empty
                </h2>
                <p className="text-gray-600 mb-6">
                  It looks like you don't have any event actions
                </p>
                <button
                  className="flex  h-10  px-4 py-2 bg-[#BBD6ED] rounded shadow hover:shadow-inner"
                  onClick={() => {
                    setShowAddEvent(true);
                    setSelectedEvent("");
                  }}
                >
                  + Add Event
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      {isShowAddEvent && (
        <form onSubmit={saveObjectEvent} className="flex flex-col space-y-2 ">
          <button
            className="flex self-start h-10  px-4 py-2 bg-[#BBD6ED] rounded shadow hover:shadow-inner"
            onClick={() => {
              setShowAddEvent(false);
              resetSelections();
            }}
          >
            Back
          </button>
          <div className="grid grid-cols-2 gap-2 items-start">
            <div className="space-y-1 flex flex-col items-start gap-1">
              <label
                htmlFor="pre-execution"
                className="block text-sm font-medium text-gray-700"
              >
                Select Event
              </label>
              <select
                className="  w-[calc(100%-8px)] h-[42px] p-2 border rounded-md flex items-center justify-between cursor-pointer bg-white border-gray-100"
                onChange={handleEventChange}
                value={selectedEvent}
              >
                <option className="text-gray-400" value="">
                  Select a Event
                </option>
                {commands.map((command, index) => (
                  <option key={index} value={command.name}>
                    on:{command.name}
                  </option>
                ))}
              </select>
            </div>
            {selectedEvent && (
              <div className="space-y-1 flex flex-col items-start gap-1">
                <label
                  htmlFor="event"
                  className="block text-sm font-medium text-gray-700"
                >
                  Select Action
                </label>
                <select
                  className="p-2 mx-1 rounded border-[#ccc] border w-[calc(100%-8px)]"
                  onChange={handleActionChange}
                  value={selectedAction}
                >
                  <option value="">Select a Actions</option>
                  {ACTIONS.map((command, index) => (
                    <option key={index} value={command.actionName}>
                      {command.actionName}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
          {selectedAction &&
            renderValueForm(
              ACTIONS.find((action) => action.actionName === selectedAction)
            )}

          <button
            type="submit"
            className="flex self-start h-10  px-4 py-2 bg-[#BBD6ED] rounded shadow hover:shadow-inner"
          >
            Save
          </button>
        </form>
      )}
    </>
  );
};

export default EventPanel;
