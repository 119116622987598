import React, { useState, useEffect, useRef } from "react";
import { useNotifications } from '@mantine/notifications'

const PropertiesEditor = ({ selectedObject, schemas }) => {
  const notifications = useNotifications()
  const [formState, setFormState] = useState({});
  const [objectProperties, setObjectProperties] = useState([]);
  const formRef = useRef();
  const inputRefs = useRef(null);
  let formFields = useRef();
  const getAllProperties = (schema, allSchemas) => {
    let properties = [];

    // Direct properties from the current schema
    const directProperties = schema.contents.filter(
      (item) => item["@type"] === "Property" || item["@type"] === "Telemetry"
    );
    properties = properties.concat(directProperties);

    // Recursively add properties from extended schemas
    if (schema.extends) {
      schema.extends.forEach((extendId) => {
        const parentSchema = allSchemas.find((s) => s["@id"] === extendId);
        if (parentSchema) {
          var parentSchemaProperties = getAllProperties(
            parentSchema,
            allSchemas
          );
          properties = parentSchemaProperties.concat(properties);
        }
      });
    }

    return properties;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    const selectedSchema = schemas.filter(
      (s) => s["@id"] === selectedObject["$metadata"]["$model"]
    )[0];
    // Initialize form data using properties from the selected schema and its extensions
    const properties = getAllProperties(selectedSchema, schemas);
    setObjectProperties(() => properties);
    const initialFormData = properties.reduce((acc, prop) => {
      acc[prop.name] = prop;
      prop.value = selectedObject[prop.name];
      return acc;
    }, {});
    setFormState(() => initialFormData);

    const getPropertyDisplayName = (key) => {
      var name = properties.filter((p) => p.name === key)[0].displayName;
      if (typeof name === "object") {
        name = name["en-US"];
      }
      return name;
    };
    const getPropertySchema = (key) => {
      var schema = properties.filter((p) => p.name === key)[0].schema;
      return schema;
    };
    // formFields.current = Object.keys(initialFormData).map((key) => (
    // <div key={key} className="grid grid-cols-[80px,1fr] items-center gap-1">
    //   <label className="property-label"> {getPropertyDisplayName(key)}</label>
    //   {getPropertySchema(key) !== "double" && (
    //     <input
    //       className="p-1 mx-2 rounded border border-[#ccc] w-[calc(100%-22px)]"
    //       type="text"
    //       name={key}
    //     />
    //   )}

    //   {getPropertySchema(key) == "double" && (
    //     <div className="grid grid-cols-3 w-11/12">
    //       <input
    //         className="p-1 mx-2 rounded border border-[#ccc] w-[calc(100%-2px)]"
    //         type="text"
    //         name={key}
    //       />
    //       <input
    //         className="p-1 mx-2 rounded border border-[#ccc] w-[calc(100%-2px)]"
    //         type="text"
    //         placeholder="min"
    //       />
    //       <input
    //         className="p-1 mx-2 rounded border border-[#ccc] w-[calc(100%-2px)]"
    //         type="text"
    //         placeholder="max"
    //       />
    //     </div>
    //   )}
    // </div>
    // ));
    formFields.current = (
      <div class="relative overflow-x-auto">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 ">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50  ">
            <tr>
              <th scope="col" class="sticky top-0 bg-[#B6D3EA]   px-4 py-2 w-4/12">
                Name
              </th>
              <th scope="col" class="sticky top-0 bg-[#B6D3EA]   px-3 py-2 w-3/12">
                Value
              </th>
              <th scope="col" class="sticky top-0 bg-[#B6D3EA]   px-3 py-2 w-1/12">
                Min
              </th>
              <th scope="col" class="sticky top-0 bg-[#B6D3EA]   px-3 py-2 w-1/12">
                Max
              </th>
              <th scope="col" class="sticky top-0 bg-[#B6D3EA]   px-3 py-2 w-2/12">
                Default
              </th>
            </tr>
          </thead>
          <tbody >
            {Object.keys(initialFormData).map((key,index) => (
              <tr key={index} class="bg-white border-b ">
                <th
                  scope="row"
                  class="px-4 py-[2px] font-medium text-gray-900 whitespace-nowrap "
                >
                  {/* <p className="w-20 truncate" title={getPropertyDisplayName(key)}> */}
                    {getPropertyDisplayName(key)}
                    {/* </p> */}
                </th>
                <td class="px-2 py-[2px]">
                  <input
                    className="p-1  rounded border-0 border-[#ccc]  w-[calc(100%-1px)]"
                    type="text"
                    name={key}
                  />
                </td>
                <td class="px-2 py-[2px]">
                {(getPropertySchema(key) === "double" || getPropertySchema(key) === "integer") && (<input
                    className="p-1  rounded border-0 border-[#ccc]  w-[calc(100%-1px)]"
                    type="text"
                    placeholder="-"
                  />)}
                </td>
                <td class="px-2 py-[2px]">
                {(getPropertySchema(key) === "double" || getPropertySchema(key) === "integer") && (<input
                    className="p-1  rounded border-0 border-[#ccc]  w-[calc(100%-1px)]"
                    type="text"
                    placeholder="-"
                  />)}
                </td>
                <td class="px-2 py-[2px]">
                  <input
                    className="p-1  rounded border-0 border-[#ccc]  w-[calc(100%-1px)]"
                    type="text"
                    placeholder=""
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }, [selectedObject]);

  useEffect(() => {
    inputRefs.current = Array.from(formRef.current.elements).reduce(
      (acc, element) => {
        if (element.name) {
          // Only include elements with a name attribute\
          element.value = formState[element.name].value;
          acc[element.name] = element;
        }
        return acc;
      },
      {}
    );
  }, [formState]);

  const convertValue = (name, value) => {
    let convertedValue = value;
    let fieldSchema = objectProperties.filter((op) => op["name"] === name)[0]
      .schema;
    if (fieldSchema) {
      switch (fieldSchema) {
        case "double":
        case "float":
        case "long":
          convertedValue = parseFloat(value);
          convertedValue = isNaN(convertedValue) ? 0 : convertedValue;
          break;
        case "integer":
          convertedValue = parseInt(value, 10);
          break;
        case "boolean":
          convertedValue = value === "true";
          break;
        case "dateTime":
          // Assuming ISO 8601 format; no conversion needed as it's already a string
          break;
        // Handle additional schema types as needed
        default:
          // For string and other unhandled types; no conversion needed
          break;
      }
    }
    return convertedValue;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = Object.keys(inputRefs.current).reduce((acc, key) => {
      acc[key] = convertValue(key, inputRefs.current[key].value);
      return acc;
    }, {});
    var mergedObject = { ...selectedObject, ...data };
    delete mergedObject.children;
    console.log(mergedObject);

    const url = `${process.env.REACT_APP_VIA_OBJECT_API}/api/object/instance`;

    fetch(url, {
      method: "PUT", // Specify the request method
      headers: {
        "Content-Type": "application/json", // Specify the content type in the header
      },
      body: JSON.stringify(mergedObject),
    })
      .then((response) => response.json()) // Parse JSON response into JavaScript object
      .then((data) => {
        console.log("Success:", data); // Handle success
        notifications.showNotification({
          title: 'Properties updated successfully!',
          message: '',
          autoClose: 5000,
          color: 'green',
          loading: false,
          disallowClose: false,
        })
      })
      .catch((error) => {
        console.error("Error:", error); // Handle errors
      });
  };

  if (!selectedObject) {
    return <div className="editor-message">Please select an item to edit.</div>;
  }

  return (
    <form ref={formRef} onSubmit={handleSubmit} className="flex flex-col">
      <div className="grid  w-full gap-2">
        <div className="h-[calc(100vh-360px)] bg-white overflow-y-auto grid">
          {formFields.current}
        </div>
      </div>
      <div className="flex self-end gap-2 mt-4">
        <button
          type="submit"
          className=" h-10 px-4 py-2 bg-[#BBD6ED] rounded shadow hover:shadow-inner uppercase font-semibold"
        >
          Save
        </button>
        <button className=" h-10 px-4 py-2 bg-[#EBF0F4] rounded shadow hover:shadow-inner uppercase font-semibold">
          Cancel
        </button>
      </div>
    </form>
  );
};

export default PropertiesEditor;
