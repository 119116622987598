import React from "react";
import Header from "../../Parts/headerComponents/header";
import Footer from "../../Parts/footerComponents/footer";
import TabNav from "../../Parts/tabNav/tabNav";
import { useLocation } from "react-router-dom";

const recipes_url = process.env.REACT_APP_VIA_RECIPES;
// const recipes_url = 'http://localhost:8083/recipes';
export default function Recipes(props) {
  const location = useLocation();
  return (
    <div>
       {location.pathname!="/" &&<Header layoutdesign={props.layoutdesign} />}
      <div className=" px-20 bg-blue-50 flex gap-4">
        <div className="flex-1">
        {location.pathname!="/" &&<TabNav tabs={props.layoutdesign.tabs} type="recipes"></TabNav>}
          <div className="bg-blue-100 pt-0 overflow-hidden">
            <iframe
              title="Recipes"
              src={recipes_url}
             className="w-full m-2 io-iframe"
            ></iframe>
          </div>
        </div>
      </div>
      {location.pathname!="/" &&<Footer layoutdesign={props.layoutdesign} />}
    </div>
  );
}