import React from 'react';

const data = [
  {
    name: "LP1",
    parts: [
      { part: "01" }, { part: "02" }, { part: "03" }, { part: "04" }, { part: "05" }, { part: "06" },
      { part: "07" }, { part: "08" }, { part: "09" }, { part: "10" }, { part: "11" }, { part: "12" },
      { part: "13" }, { part: "14" }, { part: "15" }, { part: "16" }, { part: "17" }, { part: "18" },
      { part: "19" }, { part: "20" }, { part: "21" }, { part: "22" }, { part: "23" }, { part: "24" }, { part: "25" }
    ]
  },
  {
    name: "LP2",
    parts: [
      { part: "01" }, { part: "02" }, { part: "03" }, { part: "04" }, { part: "05" }, { part: "06" },
      { part: "07" }, { part: "08" }, { part: "09" }, { part: "10" }, { part: "11" }, { part: "12" },
      { part: "13" }, { part: "14" }, { part: "15" }, { part: "16" }, { part: "17" }, { part: "18" },
      { part: "19" }, { part: "20" }, { part: "21" }, { part: "22" }, { part: "23" }, { part: "24" }, { part: "25" }
    ]
  },
  {
    name: "LP3",
    parts: [
      { part: "01" }, { part: "02" }, { part: "03" }, { part: "04" }, { part: "05" }, { part: "06" },
      { part: "07" }, { part: "08" }, { part: "09" }, { part: "10" }, { part: "11" }, { part: "12" },
      { part: "13" }, { part: "14" }, { part: "15" }, { part: "16" }, { part: "17" }, { part: "18" },
      { part: "19" }, { part: "20" }, { part: "21" }, { part: "22" }, { part: "23" }, { part: "24" }, { part: "25" }
    ]
  }
];

export default function RightInterface(props) {
  return (<>
    <div className='flex gap-4 mt-7'>
      {data.map((item, index) => (
        <div key={index} className='border border-gray-400 border-opacity-60 w-4/5 bg-customGray rounded p-3 relative'>
          <div className='absolute top-[-5%] bg-customGray w-3/4 left-1/2 transform -translate-x-1/2'>
            <h1 className='font-bold text-xs font-sans border border-gray-400 text-gray-600 border-opacity-70 rounded-sm text-center px-1 py-[6%]'>
              {item.name}
            </h1>
          </div>
          <div className='space-y-3'>
            <div className='grid grid-cols-2 gap-1 mt-3'>
              <div className='col-span-1 space-y-[2px]'>
                {item.parts.slice(0, 12).map((part, partIndex) => (
                  <h1 key={partIndex} className='text-white bg-zinc-500 font-bold rounded-xl flex items-center' style={{ fontSize: '6px', padding: '0px 6px' }}>
                    {part.part}
                  </h1>
                ))}
              </div>
              <div className='col-span-1 space-y-[2px]'>
                {item.parts.slice(12).map((part, partIndex) => (
                  <h1 key={partIndex} className={`font-bold rounded-xl flex items-center ${partIndex < 11 ? 'text-white bg-zinc-500' : 'text-black bg-zinc-300'}`} style={{ fontSize: '6px', padding: '0px 6px' }}>
                    {part.part}
                  </h1>
                ))}
              </div>
            </div>
            <div className='w-full bg-gray-300 shadow-inner shadow-gray-400 rounded-sm h-2 mb-4'>
              <div className='bg-green-500 shadow-inner shadow-gray-600 h-2 rounded-sm' style={{ width: '45%' }}></div>
            </div>
            <div className='text-center text-xs font-bold space-y-3'>
              <button className='bg-btnBg w-full rounded text-gray-600 px-1 py-[6%] custom-shadow custom-shadow-hover'>LOAD</button>
              <button className='bg-btnBg w-full rounded text-gray-600 px-1 py-[6%] custom-shadow custom-shadow-hover'>UNLOAD</button>
            </div>
          </div>
        </div>
      ))}
    </div>
      <div className='border border-gray-400 border-opacity-60 bg-customGray rounded p-3 mt-3'>
        <button className='bg-btnBg w-full rounded text-gray-600 px-1 py-[6%] custom-shadow custom-shadow-hover font-bold'>Make All Online</button>
      </div>
  </>);
}

