import React, { useEffect, useRef, useState } from 'react';

import { ChannelService, ChannelSubscriptionService } from "@getvia/sdk";
import { getAllChambers, getAllRecipesByChamber } from '../../api/api';
const viaConfig = {
  channelServiceOptions: {
    apiToken: "test",
    baseUrl: process.env.REACT_APP_VIA_CORE_API,
    wsUrl: process.env.REACT_APP_VIA_NOTIFICATION_HUB
  },
};
const channelService = new ChannelService(viaConfig.channelServiceOptions);
  export default function E95sidemenu() { 

    const channels = useRef();
    const startBtnRef = useRef(null);
    const [popup,setPopup]= useState(false)
    const [jobID,setjobID]= useState(null)
    const [cycles,setCycles]= useState(1)
    const [routRecipe,setRoutRecipe]= useState('LL-PM1')
    const [processRecipe,setProcessRecipe]= useState()

    const [recipes, setRecipes] = useState([]);
    const fetchChannelsStates = async () => {
      const response = await channelService.ChannelStates();
      channels.current = response;
    };

    const handleMainStart = (e) => {
      if(startBtnRef.current && startBtnRef.current.classList !== null) {
        startBtnRef.current.classList.add("pulse")
        setTimeout(() => {
          startBtnRef.current.classList.remove("pulse")
        }, 500)
      }
      
    }

    const IOWrite = (channelName, value) => {
      if (channelName) {
        const channel = channels.current.find(obj => {
          return obj.channelName === channelName
        })
        if (channel) {
          channel.data.value = value;
          channelService.Write(channel).then(() => {
          });
        }
      }
    }
    const WriteToChannel = async (a) => {

      
      const writeChannelName = 'LP1.JobCmd';
      await IOWrite('LP1.JobId', jobID);
      // await IOWrite('LP1.RouteRecipe', routRecipe);
      await IOWrite('LP1.ProcessRecipe', processRecipe);
      await IOWrite('LP1.NoOfCycles', cycles);
      await IOWrite(writeChannelName, a);

      setPopup(false)
    }

    const getRecipes = async () => {
      const chambers = await getAllChambers()
      if(chambers){
        const chamber = chambers?.filter((x)=> x.chamberName == 'Dicing RIE Chamber')[0]
        const recipes = await getAllRecipesByChamber(chamber.id);
        if (recipes) {
          setRecipes(recipes.DATA);
        }
      }
    };
    useEffect(() => {
      getRecipes();
    }, []);
    useEffect(() => {
      const fetChannelData = async () => {
        await fetchChannelsStates();
      };
      fetChannelData();
    })

    // useEffect(() => {
    //   startBtnRef.current.addEventListener('click', handleMainStart);
    // }, [])
    return (<>
    {/* Updated code */}

    <div className="flex gap-2 w-full h-full p1 dysingcontainer-bg ">
        <div className="dysing-bg pt-3 px1 py-3 rounded-md w-28">
          <div className="  ">
            <button
              type="button"
              className="font-['Arial'] text-black bg-[#E7EB3E] h-[6%] w-[80%] rounded-lg px-5 py-5 ms-2.5 me-2.5 mb-4 border-[5px] border-[#9ca3af] font-bold text-[13px] shadow-lg hover:shadow-xl "
            >
              MAIN
            </button>
            <button
              type="button"
              className="font-['Arial'] text-black bg-[#D4FCDA] h-[7%] w-[80%] rounded-lg px-18 py-5 ms-2.5 me-2.5 mb-4 border-[5px] border-[#9ca3af] font-bold text-[13px] shadow-lg hover:shadow-xl"
            >
              INTIALIZE
            </button>

            <button
              type="button"
              className="font-['Arial'] shadow-[-5px_-5px_5px_-2px_#d4e9fc,_5px_5px_5px_-2px_rgba(0,0,0,0.15)] text-#525459 bg-[#B9CFE4] h-[6%] w-[80%] rounded-lg px-2.5 py-4 ms-2.5 me-2.5  mb-4  font-bold text-[13px] hover:bg-[#e8f2ff] transition duration-500 shadow-lg hover:shadow-xl "
              onClick={() => setPopup(true)}
            >
              <svg
                class="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 10 18"
                transform="translate(18,-4.5)"
              >
                <path d="M3.414 1A2 2 0 0 0 0 2.414v11.172A2 2 0 0 0 3.414 15L9 9.414a2 2 0 0 0 0-2.828L3.414 1Z" fill='#525459' />
              </svg>
              START
            </button>

            <button
              type="button"
              className="font-['Arial'] shadow-[-5px_-5px_5px_-2px_#d4e9fc,_5px_5px_5px_-2px_rgba(0,0,0,0.15)] text-#525459 bg-[#B9CFE4] h-[6%] w-[80%] rounded-lg px-2.5 py-4 ms-2.5 me-2.5 mb-4 font-bold text-[13px] hover:bg-[#e8f2ff] transition duration-500 shadow-lg hover:shadow-xl"
              onClick={() => WriteToChannel(2)}
            >
              <svg
                class="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
                transform="translate(18,-3.5)"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H8Zm7 0a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1Z"
                  clip-rule="evenodd" fill='#525459'
                />
              </svg>
              PAUSE
            </button>
            <button
              type="button"
              className="font-['Arial'] shadow-[-5px_-5px_5px_-2px_#d4e9fc,_5px_5px_5px_-2px_rgba(0,0,0,0.15)] text-#525459 bg-[#B9CFE4] h-[6%] w-[80%] rounded-lg px-5 py-4 ms-2.5 me-2.5 mb-4 font-bold text-[13px] hover:bg-[#e8f2ff] transition duration-500 shadow-lg hover:shadow-xl"
              onClick={() => WriteToChannel(0)}
            >
              <svg
                class="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="currentColor"
                viewBox="0 0 24 24"
                transform="translate(8,-4)"
              >
                <path d="M7 5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H7Z" fill='#525459' />
              </svg>
              STOP
            </button>
            <button
              type="button"
              className="font-['Arial'] shadow-[-5px_-5px_5px_-2px_#d4e9fc,_5px_5px_5px_-2px_rgba(0,0,0,0.15)] text-#525459 bg-[#B9CFE4] h-[6%] w-[80%] rounded-lg px-2.5 py-4 ms-2.5 me-2.5 mb-4 font-bold text-[13px] hover:bg-[#e8f2ff] transition duration-500 shadow-lg hover:shadow-xl"
              onClick={() => WriteToChannel(3)}
            >
              <svg
                class="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
                transform="translate(17,-2)"
              >
                <rect
                  width="12"
                  height="12"
                  x="6"
                  y="6"
                  stroke="#525459"
                  stroke-linejoin="round"
                  stroke-width="2"
                  rx="1"
                  fill=''
                />
              </svg>
              RESUME
            </button>
            <button
              type="button"
              className="font-['Arial'] shadow-[-5px_-5px_5px_-2px_#d4e9fc,_5px_5px_5px_-2px_rgba(0,0,0,0.15)] text-#525459 bg-[#B9CFE4] h-[6%] w-[80%] rounded-lg px-2.5 py-4 ms-2.5 me-2.5 mb-4 font-bold text-[13px] hover:bg-[#e8f2ff] transition duration-500 shadow-lg hover:shadow-xl"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1500 568"
                transform="translate(23,-2)"
              >
                <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" fill='#525459'/>
              </svg>
              ABORT
            </button>
          </div>

        </div>
      </div>



      

      {popup &&
      <div className='absolute w-full h-full bg-black bg-opacity-25 top-0 left-0'>
        <div className='absolute top-0 left-0 h-full w-full' onClick={()=>setPopup(false)}></div>
        <div className='bg-white rounded-md overflow-hidden relative top-1/3 w-1/4 mx-auto'>
          <div className='text-lg bg-gray-800 text-white px-3 py-2'>Start Job</div>
          <div className='text-base px-3 py-2'>
            <div className='grid grid-cols-3 gap-2'>
              <div className='py-1'>Job ID *</div>
              <div className='col-span-2'>
                <input type='text' className='border border-gray-400 rounded-md px-2 py-1 w-full' 
                value={jobID} onChange={(e)=>setjobID(e.target.value)}/>
              </div>

              
              {/* <div className='py-1'>Route Recipe</div>
              <div className='col-span-2'>
                <select type='text' className='border border-gray-400 rounded-md px-2 py-1 w-full' 
                value={routRecipe} onChange={(e)=>setRoutRecipe(e.target.value)}>
                  <option value="LL-PM1">LL-PM1</option>
                  <option value="LP1-LL-PM">LP1-LL-PM</option>
                </select>
              </div> */}

              <div className='py-1'>Process Recipe</div>
              <div className='col-span-2'>
                <select type='text' className='border border-gray-400 rounded-md px-2 py-1 w-full' 
                value={processRecipe} onChange={(e)=>setProcessRecipe(e.target.value)}>                  
                  <option>Select Recipe</option>
                    {recipes && recipes.length>0 && 
                      recipes.map((recipe, i) => 
                      <option key={recipe.id+i+"recipe-list"} value={recipe.id}>{recipe.recipeName}</option>
                    )}
                </select>
              </div>
              <div className='py-1'>No of cycles</div>
              <div className='col-span-2'>
                <input type='number' className='border border-gray-400 rounded-md px-2 py-1 w-full' 
                value={cycles} onChange={(e)=>setCycles(e.target.value)}/>
              </div>
              
            </div>
            <div className='grid grid-cols-2 gap-4 mt-3'>
              <button className='bg-gray-800 hover:bg-gray-700 text-lg p-2 rounded-md text-white font-semibold' onClick={()=>WriteToChannel(1)}>Start</button>
              <button className='bg-gray-200 hover:bg-gray-300 text-lg  p-2 rounded-md font-semibold'  onClick={()=>setPopup(false)}>Cancel</button>
            </div>
           </div>
        </div>
      </div>
  }
   </> )
  }