import React, { useEffect, useState, useRef } from 'react';

  export default function Selectbox(props) { 
    const {  data, value,setValue,w, setOpen,open,type } = props;
   const selectBoxRef = useRef(null);

 // Handle click outside the select box
  useEffect(() => {
    if(open==type) {
      function handleClickOutside(event) {
        // Check if the click is outside this specific Selectbox
        if (selectBoxRef.current && !selectBoxRef.current.contains(event.target)) {
          setOpen(''); // Hide the dropdown
        }
      }
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
   
  }, [selectBoxRef]);
    return (    <>
        <div className={`relative ${w} `} ref={selectBoxRef}>
          <div className=' p-2 bg-gray-200 w-full cursor-pointer' onClick={()=>setOpen(type)}>
          {data?.map((i,j)=>(
            i.value==value&&             i.label
            ))}
          </div>
          <div className={` absolute bg-white rounded shadow max-h-64 z-20 w-full border overflow-y-scroll border-gray-400 ${open!=type&& 'hidden'}`}>
            {data?.map((i,j)=>(
            <div onClick={()=>{setValue(i.value);setOpen('')}} className='p-2 text-md border-b w-full hover:bg-gray-200 cursor-pointer'>  {i.label}</div>
            ))}
          </div>
        </div>
      </>)
  }